import React from 'react';
import { settings } from '../../config/settings';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';

export default function FeaturedBanners() {
  return (
    <div className="relative group">
      <Swiper
        modules={[Navigation, Autoplay]}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        className="rounded-2xl overflow-hidden aspect-[900/420]"
      >
        {settings.banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <img
              src={banner.desktop}
              alt={banner.title}
              className="w-full h-full object-cover"
              loading={index === 0 ? 'eager' : 'lazy'}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <button className="swiper-button-prev absolute left-4 top-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-black/50 text-white flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity z-10 after:content-['']">
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button className="swiper-button-next absolute right-4 top-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-black/50 text-white flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity z-10 after:content-['']">
        <ChevronRight className="w-6 h-6" />
      </button>
    </div>
  );
} 