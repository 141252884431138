interface DeliveryFee {
  minOrderValue: number;
  fee: number;
}

interface CityRules {
  minOrderValue: number;
  deliveryFees: DeliveryFee[];
  freeShippingThreshold: number;
  deliveryTime: {
    min: number;
    max: number;
  };
}

export const deliveryRules: Record<string, CityRules> = {
  'Goiânia': {
    minOrderValue: 50.00,
    deliveryFees: [
      { minOrderValue: 0, fee: 14.90 },
      { minOrderValue: 100, fee: 7.90 }
    ],
    freeShippingThreshold: 150.00,
    deliveryTime: {
      min: 30,
      max: 90
    }
  },
  'Aparecida de Goiânia': {
    minOrderValue: 50.00,
    deliveryFees: [
      { minOrderValue: 0, fee: 24.90 },
      { minOrderValue: 150, fee: 14.90 }
    ],
    freeShippingThreshold: 250.00,
    deliveryTime: {
      min: 30,
      max: 120
    }
  }
};

export const calculateDeliveryFee = (city: string, orderValue: number): number => {
  const rules = deliveryRules[city];
  if (!rules) return 0;

  if (orderValue >= rules.freeShippingThreshold) return 0;

  const applicableFee = [...rules.deliveryFees]
    .reverse()
    .find(fee => orderValue >= fee.minOrderValue);

  return applicableFee ? applicableFee.fee : rules.deliveryFees[0].fee;
};

export const getDeliveryTimeRange = (city: string): { min: number; max: number } => {
  const rules = deliveryRules[city];
  if (!rules) return { min: 0, max: 0 };
  return rules.deliveryTime;
};

export const getMinOrderValue = (city: string): number => {
  const rules = deliveryRules[city];
  return rules ? rules.minOrderValue : 0;
}; 