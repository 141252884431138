import { useState, useEffect } from 'react';
import axios from 'axios';
import { settings } from '../config/settings';
import type { Address } from '../types/address';

export function useGeocoding(address: Address) {
  const [coordinates, setCoordinates] = useState<[number, number] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const geocode = async () => {
      if (!address) return;
      
      setIsLoading(true);
      setError(null);

      try {
        // Tenta primeiro com endereço completo
        const fullQuery = `${address.logradouro}, ${address.bairro}, ${address.localidade}, ${address.uf}, Brasil`;
        console.log('Tentando geocodificação com endereço completo:', fullQuery);
        
        let response = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(fullQuery)}&format=json&limit=1&countrycodes=br`
        );

        // Se não encontrar, tenta com bairro e cidade
        if (response.data.length === 0) {
          const neighborhoodQuery = `${address.bairro}, ${address.localidade}, ${address.uf}, Brasil`;
          console.log('Tentando geocodificação com bairro:', neighborhoodQuery);
          
          response = await axios.get(
            `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(neighborhoodQuery)}&format=json&limit=1&countrycodes=br`
          );
        }

        // Se ainda não encontrar, tenta só com a cidade
        if (response.data.length === 0) {
          const cityQuery = `${address.localidade}, ${address.uf}, Brasil`;
          console.log('Tentando geocodificação com cidade:', cityQuery);
          
          response = await axios.get(
            `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(cityQuery)}&format=json&limit=1&countrycodes=br`
          );
        }

        if (response.data.length > 0) {
          const { lat, lon } = response.data[0];
          setCoordinates([parseFloat(lat), parseFloat(lon)]);
        } else {
          throw new Error('Localização não encontrada');
        }
      } catch (err) {
        console.error('Erro na geocodificação:', err);
        setError('Erro ao obter coordenadas do endereço');
        setCoordinates(null);
      } finally {
        setIsLoading(false);
      }
    };

    if (address) {
      geocode();
    }
  }, [address]);

  return {
    coordinates,
    isLoading,
    error
  };
}