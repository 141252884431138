import React, { useState, useEffect } from 'react';
import { Search, X } from 'lucide-react';
import { settings } from '../../config/settings';
import SearchResults from '../search/SearchResults';

interface SearchBarProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SearchBar({ isOpen, onClose }: SearchBarProps) {
  const [query, setQuery] = useState('');
  const { store } = settings;

  useEffect(() => {
    if (isOpen) {
      // Check for initial search term when modal opens
      const initialTerm = sessionStorage.getItem('initialSearchTerm');
      if (initialTerm) {
        setQuery(initialTerm);
        sessionStorage.removeItem('initialSearchTerm');
      }
    } else {
      setQuery('');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-[#0a0118] z-50 animate-in fade-in duration-200">
      <div className="max-w-2xl mx-auto px-4">
        {/* Logo Section */}
        <div className="py-6 flex justify-center">
          <div className="h-12 glass-card rounded-lg p-2">
            <img
              src={store.logo.url}
              alt={store.logo.alt}
              className="h-full w-auto object-contain mix-blend-luminosity"
            />
          </div>
        </div>

        {/* Search Input */}
        <div className="relative">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="O que você deseja?"
            className="w-full pl-12 pr-12 py-4 glass-card rounded-xl
                     text-white placeholder-gray-400
                     focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-[#0a0118]
                     transition-all duration-300"
            autoFocus
          />
          <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-purple-400" />
          <button
            onClick={onClose}
            className="absolute right-4 top-1/2 -translate-y-1/2 p-1.5 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="h-5 w-5 text-gray-400" />
          </button>
        </div>

        {/* Popular Searches */}
        {!query && (
          <div className="mt-8">
            <h3 className="text-sm font-medium text-gray-400 mb-3">
              Buscas populares
            </h3>
            <div className="flex flex-wrap gap-2">
              {['iPhone', 'Samsung', 'Xiaomi', 'AirPods', 'Smartwatch'].map((term) => (
                <button
                  key={term}
                  onClick={() => setQuery(term)}
                  className="px-4 py-2 rounded-full text-sm
                           glass-card text-gray-300
                           hover:bg-purple-500/20 hover:text-white
                           transition-all duration-200 transform hover:scale-105"
                >
                  {term}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Search Results */}
        <div className="mt-6 pb-20 overflow-auto max-h-[calc(100vh-220px)]">
          <SearchResults query={query} onClose={onClose} />
        </div>
      </div>
    </div>
  );
}