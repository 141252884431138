import React from 'react';
import { useSearchProducts } from '../../hooks/useProducts';
import { Product } from '../../types/woocommerce';
import { Star, ShoppingCart, Loader, Check } from 'lucide-react';
import { useCart } from '../../hooks/useCart';
import { settings } from '../../config/settings';
import Prefetch from '../common/Prefetch';

interface SearchResultsProps {
  query: string;
  onClose: () => void;
}

export default function SearchResults({ query, onClose }: SearchResultsProps) {
  const { data: products, isLoading, error } = useSearchProducts(query);
  const addItem = useCart((state) => state.addItem);
  const { payment } = settings;
  const [addedProducts, setAddedProducts] = React.useState<Record<number, boolean>>({});

  if (!query.trim() || query.length < 2) return null;

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader className="w-6 h-6 text-purple-500 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-400">
        Erro ao buscar produtos. Tente novamente.
      </div>
    );
  }

  if (!products?.length) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-400">
          Nenhum produto encontrado para "{query}"
        </p>
      </div>
    );
  }

  const handleProductClick = (productId: number) => {
    onClose();
    window.location.href = `/product/${productId}`;
  };

  const handleAddToCart = (e: React.MouseEvent, product: Product) => {
    e.stopPropagation();
    addItem({
      id: product.id,
      name: product.name,
      price: parseFloat(product.price),
      quantity: 1,
      image: product.images[0]?.src || '',
    });
    
    setAddedProducts(prev => ({ ...prev, [product.id]: true }));
    
    setTimeout(() => {
      setAddedProducts(prev => ({ ...prev, [product.id]: false }));
    }, 1500);
  };

  return (
    <div className="grid grid-cols-2 gap-3 p-4">
      {products.map((product) => {
        const price = parseFloat(product.price);
        const pixPrice = price * (1 - payment.pixDiscount / 100);

        return (
          <Prefetch key={product.id} productId={product.id}>
            <div
              onClick={() => handleProductClick(product.id)}
              className="glass-card rounded-xl overflow-hidden cursor-pointer transform transition-all duration-300 hover:scale-[1.02]"
            >
              <div className="aspect-square bg-gradient-radial from-gray-800 via-gray-900 to-black p-2">
                <img
                  src={product.images[0]?.src}
                  alt={product.name}
                  className="w-full h-full object-contain"
                  loading="lazy"
                />
              </div>
              
              <div className="p-3 space-y-2">
                <h3 className="text-xs font-medium text-white line-clamp-2">
                  {product.name}
                </h3>
                
                <div className="space-y-1">
                  <div className="text-sm font-bold text-green-400">
                    R$ {pixPrice.toFixed(2)}
                  </div>
                  <div className="text-[10px] text-gray-400">
                    no PIX ({payment.pixDiscount}% off)
                  </div>
                </div>

                <button
                  onClick={(e) => handleAddToCart(e, product)}
                  className="w-full h-8 bg-gradient-to-br from-purple-500 to-pink-500 
                           text-white text-xs font-medium rounded-lg
                           hover:from-purple-400 hover:to-pink-400 transition-all duration-300
                           flex items-center justify-center gap-2"
                >
                  {addedProducts[product.id] ? (
                    <>
                      <Check className="w-4 h-4" />
                      Adicionado
                    </>
                  ) : (
                    <>
                      <ShoppingCart className="w-4 h-4" />
                      Adicionar
                    </>
                  )}
                </button>
              </div>
            </div>
          </Prefetch>
        );
      })}
    </div>
  );
}