import React, { useState, useCallback, useEffect } from 'react';
import { MapPin } from 'lucide-react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import { useGeocoding } from '../../hooks/useGeocoding';
import type { Address } from '../../types/address';

// Estilo personalizado para o marcador
const customIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

interface DeliveryMapProps {
  onConfirmLocation: (data: {
    address: string;
    city: string;
    lat: number;
    lng: number;
    cep: string;
  }) => void;
}

// Componente para atualizar a visualização do mapa
function MapUpdater({ center }: { center: [number, number] }) {
  const map = useMap();
  React.useEffect(() => {
    map.setView(center, 15);
  }, [center, map]);
  return null;
}

export default function DeliveryMap({ onConfirmLocation }: DeliveryMapProps) {
  const [coordinates, setCoordinates] = useState<[number, number] | null>(null);
  const [markerPosition, setMarkerPosition] = useState<[number, number] | null>(null);
  const [cep, setCep] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [addressData, setAddressData] = useState<Address | null>(null);

  // Hook de geocodificação
  const { coordinates: geocodedCoords, isLoading: isGeocoding } = useGeocoding(addressData);

  // Atualiza coordenadas quando a geocodificação retorna resultado
  useEffect(() => {
    if (geocodedCoords) {
      setCoordinates(geocodedCoords);
      setMarkerPosition(geocodedCoords);
    }
  }, [geocodedCoords]);

  // Função para buscar CEP
  const handleCepSearch = async () => {
    if (cep.length !== 8) {
      setError('CEP inválido');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      
      if (response.data.erro) {
        setError('CEP não encontrado');
        return;
      }

      if (!['Goiânia', 'Aparecida de Goiânia'].includes(response.data.localidade)) {
        setError('Desculpe, no momento atendemos apenas Goiânia e Aparecida de Goiânia.');
        return;
      }

      const address: Address = {
        cep: response.data.cep,
        logradouro: response.data.logradouro,
        bairro: response.data.bairro,
        localidade: response.data.localidade,
        uf: response.data.uf
      };

      setAddressData(address);
    } catch (err) {
      console.error('Erro ao buscar CEP:', err);
      setError('Erro ao buscar endereço');
    } finally {
      setLoading(false);
    }
  };

  // Função para usar localização atual
  const handleUseLocation = () => {
    setLoading(true);
    setError(null);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const coords: [number, number] = [position.coords.latitude, position.coords.longitude];
        
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${coords[0]}&lon=${coords[1]}&format=json`
          );

          const result = response.data;
          const city = result.address.city || result.address.town || result.address.municipality;

          if (!['Goiânia', 'Aparecida de Goiânia'].includes(city)) {
            setError('Desculpe, no momento atendemos apenas Goiânia e Aparecida de Goiânia.');
            return;
          }

          setCoordinates(coords);
          setMarkerPosition(coords);

          // Atualiza dados do endereço
          setAddressData({
            cep: result.address.postcode || '',
            logradouro: result.address.road || '',
            bairro: result.address.suburb || '',
            localidade: city,
            uf: result.address.state || ''
          });
        } catch (err) {
          console.error('Erro ao obter detalhes do endereço:', err);
          setError('Erro ao obter detalhes do endereço');
        } finally {
          setLoading(false);
        }
      },
      () => {
        setError('Não foi possível obter sua localização');
        setLoading(false);
      }
    );
  };

  // Função para confirmar localização
  const handleConfirm = async () => {
    if (!markerPosition || !addressData) return;

    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${markerPosition[0]}&lon=${markerPosition[1]}&format=json`
      );

      const result = response.data;
      const city = result.address.city || result.address.town || result.address.municipality;

      if (!['Goiânia', 'Aparecida de Goiânia'].includes(city)) {
        setError('Desculpe, no momento atendemos apenas Goiânia e Aparecida de Goiânia.');
        return;
      }

      onConfirmLocation({
        address: `${addressData.logradouro}, ${addressData.bairro}`,
        city: addressData.localidade,
        lat: markerPosition[0],
        lng: markerPosition[1],
        cep: addressData.cep
      });
    } catch (err) {
      setError('Erro ao confirmar localização');
    }
  };

  const isLoading = loading || isGeocoding;

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {/* Input de CEP */}
        <div className="flex gap-2 w-[100%]">
          <div className="w-[50%]">
            <input
              type="number"
              value={cep}
              onChange={(e) => setCep(e.target.value.replace(/\D/g, ''))}
              placeholder="Digite seu CEP"
              className="w-full bg-[#2a1f3e] border border-purple-600 rounded-lg px-4 py-3 text-white placeholder-gray-400"
              maxLength={8}
            />
          </div>
          <div className="w-[50%]">
            <button
              onClick={handleCepSearch}
              disabled={isLoading || cep.length !== 8}
              className="w-full bg-purple-600 hover:bg-purple-700 disabled:bg-purple-800 disabled:cursor-not-allowed text-white px-6 py-3 rounded-lg transition-colors whitespace-nowrap"
            >
              {isLoading ? 'Buscando...' : 'Buscar'}
            </button>
          </div>
        </div>

        {/* Botão de localização */}
        <button
          onClick={handleUseLocation}
          disabled={isLoading}
          className="w-full flex items-center justify-center gap-2 bg-purple-600 hover:bg-purple-700 disabled:bg-purple-800 disabled:cursor-not-allowed text-white py-3 rounded-lg transition-colors"
        >
          <MapPin className="w-5 h-5" />
          {isLoading ? 'Obtendo localização...' : 'Usar Minha Localização'}
        </button>

        {/* Mensagem de erro */}
        {error && (
          <div className="text-red-400 text-sm p-3 bg-red-400/10 rounded-lg">
            {error}
          </div>
        )}
      </div>

      {/* Mapa */}
      {coordinates && (
        <div className="space-y-4">
          <div className="h-[300px] rounded-lg overflow-hidden">
            <MapContainer
              center={coordinates}
              zoom={15}
              style={{ height: '100%', width: '100%' }}
              zoomControl={true}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MapUpdater center={coordinates} />
              {markerPosition && (
                <Marker
                  position={markerPosition}
                  draggable={true}
                  icon={customIcon}
                  eventHandlers={{
                    dragend: (e) => {
                      const marker = e.target;
                      const pos = marker.getLatLng();
                      setMarkerPosition([pos.lat, pos.lng]);
                    },
                  }}
                />
              )}
            </MapContainer>
          </div>

          <button
            onClick={handleConfirm}
            disabled={isLoading || !markerPosition || !addressData}
            className="w-full bg-purple-600 hover:bg-purple-700 disabled:bg-purple-800 disabled:cursor-not-allowed text-white py-3 rounded-lg transition-colors"
          >
            {isLoading ? 'Verificando localização...' : 'Estou neste local'}
          </button>
        </div>
      )}
    </div>
  );
} 