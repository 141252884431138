import React, { Suspense } from 'react';
import { useProducts } from '../hooks/useProducts';
import { useCategories } from '../hooks/useCategories';
import { settings } from '../config/settings';
import { themeClasses } from '../utils/theme';
import MetaTags from '../components/common/MetaTags';
import FeaturedBanners from '../components/home/FeaturedBanners';
import AboutStore from '../components/home/AboutStore';

// Lazy loading dos componentes pesados
const FeaturedProducts = React.lazy(() => import('../components/home/FeaturedProducts'));
const Categories = React.lazy(() => import('../components/home/Categories'));
const SmartSearch = React.lazy(() => import('../components/home/SmartSearch'));

const LoadingSpinner = () => (
  <div className="flex items-center justify-center p-4">
    <div className={`animate-spin w-8 h-8 border-4 border-[${settings.theme.colors.primary.from}] border-t-transparent rounded-full`} />
  </div>
);

export default function Home() {
  const { data, isLoading: productsLoading } = useProducts();
  const { data: categories, isLoading: categoriesLoading } = useCategories();
  const { store } = settings;

  // Extraindo produtos da primeira página
  const products = data?.pages[0]?.products || [];

  return (
    <div className={`min-h-screen ${themeClasses.background.primary}`}>
      <MetaTags 
        title={store.name}
        description={store.description}
        image={store.logo.url}
      />
      
      {/* Background Effects */}
      <div className="fixed inset-0 pointer-events-none">
        <div className={`absolute inset-0 ${themeClasses.gradient.radial}`} />
        <div className={`absolute inset-0 ${themeClasses.effects.grid}`} />
      </div>

      {/* Main Content */}
      <main className="relative max-w-7xl mx-auto px-4 py-6 space-y-8">
        {/* Smart Search */}
        <Suspense fallback={<LoadingSpinner />}>
          <SmartSearch />
        </Suspense>

        {/* Featured Banners */}
        <FeaturedBanners />

        {/* Categories */}
        <section>
          <h2 className={`text-xl font-bold ${themeClasses.gradient.text.primary} mb-4`}>
            Categorias
          </h2>
          <Suspense fallback={<LoadingSpinner />}>
            {!categoriesLoading && categories && (
              <Categories categories={categories} />
            )}
          </Suspense>
        </section>

        {/* Featured Products */}
        <section>
          <h2 className={`text-xl font-bold ${themeClasses.gradient.text.primary} mb-4`}>
            Produtos em Destaque
          </h2>
          <Suspense fallback={<LoadingSpinner />}>
            {!productsLoading && products && (
              <FeaturedProducts products={products} />
            )}
          </Suspense>
        </section>

        {/* About Store */}
        <AboutStore />
      </main>
    </div>
  );
}