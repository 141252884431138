interface StoreHours {
  dayOfWeek: number[];  // 0 = Domingo, 1 = Segunda, etc.
  open: string;
  close: string;
}

interface StoreSettings {
  name: string;
  description: string;
  url: string;
  logo: {
    url: string;
    alt: string;
  };
  address: {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    zipCode: string;
    googleMapsUrl: string;
  };
  contact: {
    phone: string;
    whatsapp: string;
  };
  hours: {
    delivery: StoreHours[];
    pickup: {
      hours: StoreHours[];
      preparationTime: number; // em minutos
    };
  };
  location: {
    lat: number;
    lng: number;
  };
  about: string;
}

export const settings = {
  store: {
    name: "iFody Sex Shop Goiânia",
    description: 'Sua sex shop online com entrega discreta e produtos de qualidade. Compre com segurança e privacidade.',
    url: 'https://idesire.goiania.br',

    logo: {
      url: 'https://idesire.goiania.br/loja/wp-content/uploads/2023/10/ezgif.com-resize1-1.webp',
      alt: 'iDesire Sex Shop'
    },
    address: {
      street: "Rua U-31",
      number: "31",
      complement: "Qd 6, Lt 7",
      neighborhood: "Vila União",
      city: "Goiânia",
      state: "GO",
      zipCode: "74313-140",
      googleMapsUrl: "https://www.google.com/maps/place/Sex+Shop+Goi%C3%A2nia+-+iFody+Delivery+de+Produtos+Er%C3%B3ticos/@-16.7114232,-49.3075454,15z/data=!4m2!3m1!1s0x0:0xe10dd42e707b8579"
    },
    contact: {
      phone: '6230000000',
      whatsapp: '5562999999999'
    },
    hours: {
      delivery: [
        {
          dayOfWeek: [1, 2, 3, 4, 5, 6], // Segunda a Sábado
          open: '13:00',
          close: '21:00'
        }
      ],
      pickup: {
        hours: [
          {
            dayOfWeek: [1, 2, 3, 4, 5, 6], // Segunda a Sábado
            open: '13:00',
            close: '21:00'
          }
        ],
        preparationTime: 15 // 15 minutos para separação
      }
    },
    location: {
      lat: -16.7114232,
      lng: -49.3075454
    },
    about: 'Somos uma sex shop online comprometida com sua privacidade e satisfação. Oferecemos produtos de qualidade com entrega discreta e segura.'
  },
  theme: {
    colors: {
      primary: {
        from: '#9333EA', // purple-600
        to: '#EC4899'    // pink-500
      },
      secondary: {
        from: '#10B981', // emerald-500
        to: '#059669'    // emerald-600
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#9CA3AF',
        accent: '#A855F7'
      },
      background: {
        primary: '#0a0118',
        card: 'rgba(255, 255, 255, 0.05)',
        border: 'rgba(255, 255, 255, 0.1)'
      }
    },
    effects: {
      grid: {
        pattern: '/patterns/grid.svg',
        size: '100px',
        opacity: 0.2
      },
      glass: {
        blur: 'lg',
        opacity: 0.05,
        borderOpacity: 0.1
      },
      gradient: {
        radial: {
          opacity: 0.2,
          stops: ['20%', '50%', '100%']
        }
      }
    }
  },
  banners: [
    {
      desktop: "https://idesire.goiania.br/loja/wp-content/uploads/2024/10/WhatsApp-Image-2023-09-27-at-00.10.06-1400x525-1.jpeg",
      mobile: "https://idesire.goiania.br/loja/wp-content/uploads/2024/10/WhatsApp-Image-2023-09-27-at-00.10.06-1400x525-1.jpeg",
    },
    {
      desktop: "https://idesire.goiania.br/loja/wp-content/uploads/2024/02/1-banner-05-1300x500px.png-1.webp",
      mobile: "https://idesire.goiania.br/loja/wp-content/uploads/2024/02/1-banner-05-1300x500px.png-1.webp",
    }
  ],
  payment: {
    minValue: 50,
    pixDiscount: 8,
    minInstallment: 30,
    maxInstallments: 12,
    methods: {
      pix: {
        id: 'pix',
        name: 'PIX',
        icon: 'Wallet'
      },
      credit: {
        id: 'credit',
        name: 'Cartão de Crédito',
        icon: 'CreditCard'
      },
      debit: {
        id: 'debit',
        name: 'Cartão de Débito',
        icon: 'CreditCard'
      },
      cash: {
        id: 'cash',
        name: 'Dinheiro',
        icon: 'Wallet'
      }
    }
  },
  delivery: {
    center: {
      lat: -16.6869, // Coordenadas de Goiânia
      lng: -49.2648,
      address: 'Goiânia, GO'
    },
    radius: 10000, // 10km em metros
    minValue: 50, // Valor mínimo para entrega
    fee: {
      base: 10, // Taxa base de entrega
      perKm: 2  // Valor adicional por km
    },
    timeEstimate: {
      min: 30, // Tempo mínimo em minutos
      max: 60  // Tempo máximo em minutos
    }
  },
  apis: {
    opencage: {
      apiKey: "YOUR_API_KEY"
    }
  },
  contact: {
    phone: '6230000000',
    whatsapp: '5562999999999'
  }
} as const;

// Funções auxiliares para horários
export const isStoreOpen = (type: 'delivery' | 'pickup' = 'delivery'): boolean => {
  const now = new Date();
  const currentDay = now.getDay();
  const currentTime = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
  
  const hours = type === 'delivery' ? settings.store.hours.delivery : settings.store.hours.pickup.hours;
  
  return hours.some(schedule => {
    return schedule.dayOfWeek.includes(currentDay as 1 | 2 | 3 | 4 | 5 | 6) &&
           currentTime >= schedule.open &&
           currentTime <= schedule.close;
  });
};

export const getNextOpeningTime = (): Date => {
  const now = new Date();
  const currentDay = now.getDay();
  const currentTime = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
  
  // Procura o próximo horário de abertura
  for (let i = 0; i < 7; i++) {
    const checkDay = (currentDay + i) % 7;
    const schedule = settings.store.hours.delivery.find(
      s => s.dayOfWeek.includes(checkDay as 1 | 2 | 3 | 4 | 5 | 6)
    );

    if (schedule) {
      const nextDate = new Date(now);
      nextDate.setDate(now.getDate() + i);
      
      const [openHour, openMinute] = schedule.open.split(':').map(Number);
      nextDate.setHours(openHour, openMinute, 0, 0);

      // Se for hoje, verifica se já passou do horário
      if (i === 0 && currentTime > schedule.open) {
        continue;
      }

      return nextDate;
    }
  }

  // Se não encontrar, retorna o próximo dia no mesmo horário
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(9, 0, 0, 0);
  return tomorrow;
};

export const getClosingTime = (): Date | null => {
  const now = new Date();
  const currentDay = now.getDay();
  
  const todaySchedule = settings.store.hours.delivery.find(
    schedule => schedule.dayOfWeek.includes(currentDay as 1 | 2 | 3 | 4 | 5 | 6)
  );

  if (!todaySchedule) return null;

  const [closeHour, closeMinute] = todaySchedule.close.split(':').map(Number);
  const closeTime = new Date(now);
  closeTime.setHours(closeHour, closeMinute, 0, 0);

  return closeTime;
};

export const getTimeUntilClose = (): number | null => {
  const closeTime = getClosingTime();
  if (!closeTime) return null;

  const now = new Date();
  return Math.max(0, closeTime.getTime() - now.getTime());
};

export const formatTimeUntilClose = (): string | null => {
  const timeUntilClose = getTimeUntilClose();
  if (timeUntilClose === null) return null;

  const minutes = Math.floor(timeUntilClose / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0) {
    return `${hours}h${remainingMinutes > 0 ? ` ${remainingMinutes}min` : ''}`;
  }
  return `${minutes}min`;
};

export const formatTimeUntilOpen = (): string => {
  const now = new Date();
  const nextOpeningTime = getNextOpeningTime();
  const diffMs = nextOpeningTime.getTime() - now.getTime();
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const hours = Math.floor(diffMinutes / 60);
  const minutes = diffMinutes % 60;

  if (hours > 0) {
    return `${hours}h${minutes > 0 ? ` ${minutes}min` : ''}`;
  }
  return `${minutes}min`;
};