import React, { useState, useEffect } from 'react';
import { X, MapPin, Clock, Store, ShoppingBag, Truck } from 'lucide-react';
import DeliveryMap from '../delivery/DeliveryMap';
import { deliveryRules } from '../../config/deliveryRules';
import { settings, isStoreOpen, formatTimeUntilClose } from '../../config/settings';
import FreeShippingProgress from '../delivery/FreeShippingProgress';

interface DeliveryCheckModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface DeliveryAddress {
  address: string;
  city: string;
  lat: number;
  lng: number;
  cep: string;
}

export default function DeliveryCheckModal({ isOpen: isModalOpen, onClose }: DeliveryCheckModalProps) {
  const [selectedAddress, setSelectedAddress] = useState<DeliveryAddress | null>(null);
  const [showMap, setShowMap] = useState(true);
  const [cartValue] = useState(0); // Isso viria do carrinho real

  // Carregar endereço salvo
  useEffect(() => {
    const savedAddress = localStorage.getItem('deliveryAddress');
    if (savedAddress) {
      setSelectedAddress(JSON.parse(savedAddress));
      setShowMap(false);
    }
  }, []);

  const handleLocationConfirm = (address: DeliveryAddress) => {
    setSelectedAddress(address);
    setShowMap(false);
    localStorage.setItem('deliveryAddress', JSON.stringify(address));
  };

  if (!isModalOpen) return null;

  const cityRules = selectedAddress ? deliveryRules[selectedAddress.city] : null;
  const storeIsOpen = isStoreOpen();
  const timeUntilClose = formatTimeUntilClose();

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-center sm:items-center p-4 pb-20 sm:pb-4">
      <div
        className="absolute inset-0 bg-black/80 backdrop-blur-sm"
        onClick={onClose}
      />

      <div className="relative w-full max-w-2xl bg-[#0a0118] rounded-2xl overflow-hidden">
        {/* Header Fixo */}
        <div className="sticky top-0 z-10 bg-[#0a0118] border-b border-purple-500/20 p-4">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-2xl font-bold text-white">Endereço de Entrega</h2>
              <p className="text-gray-400">
                {showMap 
                  ? 'Digite seu CEP ou use sua localização atual'
                  : 'Confira as informações do seu endereço'
                }
              </p>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        {/* Conteúdo Scrollável */}
        <div className="max-h-[calc(100vh-180px)] overflow-y-auto p-4">
          <div className="space-y-6">
            {showMap ? (
              <DeliveryMap onConfirmLocation={handleLocationConfirm} />
            ) : selectedAddress ? (
              <div className="space-y-6">
                {/* Status da Loja */}
                <div className="glass-card rounded-lg p-4">
                  <div className="flex items-center gap-2">
                    <div className={`w-3 h-3 rounded-full ${storeIsOpen ? 'bg-green-400' : 'bg-red-400'} animate-pulse`} />
                    <div>
                      {storeIsOpen ? (
                        <p className="text-green-400">
                          Loja Aberta - fecha em {timeUntilClose}
                        </p>
                      ) : (
                        <p className="text-red-400">
                          Loja Fechada
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* Endereço selecionado */}
                <div className="glass-card rounded-lg p-4">
                  <div className="flex justify-between items-start">
                    <div className="space-y-1">
                      <div className="flex items-center gap-2 mb-2">
                        <MapPin className="w-5 h-5 text-purple-400" />
                        <h3 className="text-white font-medium">Seu Endereço</h3>
                      </div>
                      <p className="text-sm text-gray-300 leading-relaxed">
                        {selectedAddress.address}
                      </p>
                      <p className="text-xs text-gray-400">
                        CEP: {selectedAddress.cep}
                      </p>
                    </div>
                    <button
                      onClick={() => setShowMap(true)}
                      className="text-purple-400 hover:text-purple-300 text-sm"
                    >
                      Alterar
                    </button>
                  </div>
                </div>

                {/* Opções de Entrega */}
                <div className="glass-card rounded-lg divide-y divide-purple-500/20">
                  {/* Entrega */}
                  {cityRules && (
                    <div className="p-4">
                      <div className="flex items-start gap-3">
                        <Truck className="w-5 h-5 text-purple-400 mt-1" />
                        <div>
                          <h3 className="text-lg font-semibold text-white mb-2">
                            Entrega
                          </h3>
                          <div className="space-y-3 text-sm">
                            <div className="flex items-center gap-2">
                              <ShoppingBag className="w-4 h-4 text-purple-400" />
                              <p className="text-gray-300">
                                Pedido mínimo: <span className="text-white font-medium">R$ {cityRules.minOrderValue.toFixed(2)}</span>
                              </p>
                            </div>
                            <div className="flex items-center gap-2">
                              <Clock className="w-4 h-4 text-purple-400" />
                              <p className="text-gray-300">
                                Tempo estimado: <span className="text-white font-medium">{cityRules.deliveryTime.min}-{cityRules.deliveryTime.max} minutos</span>
                              </p>
                            </div>
                            <div className="space-y-2">
                              <p className="text-gray-300 font-medium">Taxas de Entrega:</p>
                              {cityRules.deliveryFees.map((fee, index) => (
                                <div key={index} className="flex justify-between text-sm">
                                  <span className="text-gray-400">
                                    {fee.minOrderValue > 0 
                                      ? `Acima de R$ ${fee.minOrderValue.toFixed(2)}` 
                                      : 'Padrão'}
                                  </span>
                                  <span className="text-white font-medium">R$ {fee.fee.toFixed(2)}</span>
                                </div>
                              ))}
                              <div className="flex justify-between text-sm text-green-400 font-medium">
                                <span>Acima de R$ {cityRules.freeShippingThreshold.toFixed(2)}</span>
                                <span>Frete Grátis</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Retirada */}
                  <div className="p-4">
                    <div className="flex items-start gap-3">
                      <Store className="w-5 h-5 text-purple-400 mt-1" />
                      <div>
                        <h3 className="text-lg font-semibold text-white mb-2">
                          Retirada na Loja
                        </h3>
                        <div className="space-y-3 text-sm">
                          <div className="flex items-center gap-2">
                            <Clock className="w-4 h-4 text-purple-400" />
                            <p className="text-gray-300">
                              Tempo de separação: <span className="text-white font-medium">{settings.store.hours.pickup.preparationTime} minutos</span>
                            </p>
                          </div>
                          <div className="space-y-2">
                            <p className="text-gray-300 font-medium">Endereço:</p>
                            <p className="text-gray-400 leading-relaxed">
                              {settings.store.address.street}, {settings.store.address.number}
                              <br />
                              {settings.store.address.complement}
                              <br />
                              {settings.store.address.neighborhood}, {settings.store.address.city} - {settings.store.address.state}
                            </p>
                          </div>
                          <div className="space-y-2">
                            <p className="text-gray-300 font-medium">Horário de Funcionamento:</p>
                            <p className="text-gray-400">
                              Segunda a Sábado: {settings.store.hours.pickup.hours[0].open} às {settings.store.hours.pickup.hours[0].close}
                            </p>
                          </div>
                          <a 
                            href={settings.store.address.googleMapsUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1 text-purple-400 hover:text-purple-300 transition-colors"
                          >
                            <MapPin className="w-4 h-4" />
                            Ver no Google Maps
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}