import { useInfiniteQuery, useQuery } from 'react-query';
import { woocommerceApi } from '../lib/woocommerce';
import { useFilters } from './useFilters';

interface UseProductsParams {
  perPage?: number;
  featured?: boolean;
  onSale?: boolean;
  category?: number;
  search?: string;
}

export function useProducts(params: UseProductsParams = {}) {
  const { perPage = 20, category } = params;
  const { sortBy, filters } = useFilters();

  // Parse sort options
  const [orderby, order] = sortBy.includes('-')
    ? sortBy.split('-')
    : [sortBy, 'desc'];

  // Configurar parâmetros da query
  const queryParams: any = {
    per_page: perPage,
    orderby,
    order,
  };

  if (params.search) {
    queryParams.search = params.search;
  }

  // Usar query key diferente baseado se é busca por categoria ou geral
  const queryKey = category 
    ? ['category-products', category, queryParams, sortBy, filters]
    : ['products', params, sortBy, filters];

  // Escolher função apropriada baseado se tem categoria ou não
  const fetchFunction = category
    ? ({ pageParam = 1 }) => woocommerceApi.getCategoryProducts(category, { ...queryParams, page: pageParam })
    : ({ pageParam = 1 }) => woocommerceApi.getProducts({ ...queryParams, page: pageParam });

  return useInfiniteQuery(
    queryKey,
    fetchFunction,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < lastPage.totalPages) {
          return pages.length + 1;
        }
        return undefined;
      },
      select: (data) => {
        let filteredData = { ...data };

        // Aplicar filtros de preço
        if (filters.price.length > 0) {
          filteredData.pages = data.pages.map(page => ({
            ...page,
            products: page.products.filter(product => {
              const price = parseFloat(product.price);
              return filters.price.some(range => {
                switch (range) {
                  case '0-50': return price <= 50;
                  case '50-100': return price > 50 && price <= 100;
                  case '100-200': return price > 100 && price <= 200;
                  case '200-plus': return price > 200;
                  default: return true;
                }
              });
            })
          }));
        }

        // Aplicar filtros de disponibilidade
        if (filters.availability.includes('on-sale')) {
          filteredData.pages = data.pages.map(page => ({
            ...page,
            products: page.products.filter(product => product.on_sale)
          }));
        }

        // Aplicar filtros de avaliação
        if (filters.rating.length > 0) {
          filteredData.pages = data.pages.map(page => ({
            ...page,
            products: page.products.filter(product => {
              const rating = parseFloat(product.average_rating);
              return filters.rating.some(r => {
                if (r === '4-plus') return rating >= 4;
                if (r === '3-plus') return rating >= 3;
                return true;
              });
            })
          }));
        }

        return filteredData;
      },
      staleTime: 5 * 60 * 1000, // 5 minutos
      cacheTime: 30 * 60 * 1000, // 30 minutos
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );
}

export function useSearchProducts(query: string) {
  return useQuery(
    ['products', 'search', query],
    () => woocommerceApi.searchProducts(query),
    {
      enabled: query.length >= 2,
      staleTime: 1 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    }
  );
}