import React from 'react';
import { QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { queryClient } from './lib/queryClient';
import BottomNav from './components/layout/BottomNav';
import SearchBar from './components/layout/SearchBar';
import SideMenu from './components/layout/SideMenu';
import DesktopHeader from './components/layout/DesktopHeader';
import HeaderMobile from './components/layout/HeaderMobile';
import Footer from './components/layout/Footer';
import AppRoutes from './routes';
import MetaTags from './components/common/MetaTags';

function AppContent() {
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);

  const handleOpenSearch = () => {
    setIsSideMenuOpen(false);
    setIsSearchOpen(true);
  };

  const handleOpenMenu = () => {
    setIsSearchOpen(false);
    setIsSideMenuOpen(true);
  };

  const handleOpenCart = () => {
    setIsSearchOpen(false);
    setIsSideMenuOpen(false);
    window.location.href = '/cart';
  };

  return (
    <div className="min-h-screen flex flex-col bg-[#0a0118]">
      <MetaTags />
      
      <DesktopHeader
        onOpenSearch={handleOpenSearch}
        onOpenMenu={handleOpenMenu}
        onOpenCart={handleOpenCart}
      />
      
      <HeaderMobile onOpenSearch={handleOpenSearch} />
      
      <main className="flex-1 relative mt-0 md:mt-[160px]">
        <AppRoutes onOpenSearch={handleOpenSearch} />
        
        <SearchBar isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
        <SideMenu isOpen={isSideMenuOpen} onClose={() => setIsSideMenuOpen(false)} />
        
        <div className="md:hidden">
          <BottomNav 
            onOpenSearch={handleOpenSearch}
            onOpenMenu={handleOpenMenu}
            onOpenCart={handleOpenCart}
          />
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AppContent />
      </QueryClientProvider>
    </HelmetProvider>
  );
}
