import React, { useState } from 'react';
import { X, Gift, Check } from 'lucide-react';
import './phone-input.css';
import PhoneInput from '../common/PhoneInput';

interface CouponRewardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (phone: string) => void;
}

export default function CouponRewardModal({ isOpen, onClose, onSubmit }: CouponRewardModalProps) {
  const [phone, setPhone] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(phone);
    setIsSuccess(true);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div
        className="absolute inset-0 bg-black/80 backdrop-blur-sm"
        onClick={onClose}
      />

      <div className="relative w-full max-w-md bg-[#0a0118] rounded-2xl p-4 sm:p-6">
        <button
          onClick={onClose}
          className="absolute top-3 sm:top-4 right-3 sm:right-4 p-1.5 sm:p-2 text-gray-400 hover:text-white transition-colors"
        >
          <X className="w-4 h-4 sm:w-5 sm:h-5" />
        </button>

        {isSuccess ? (
          <div className="flex flex-col items-center gap-4 sm:gap-6 py-6 sm:py-8">
            <div className="w-16 h-16 sm:w-20 sm:h-20 rounded-full bg-green-500/20 flex items-center justify-center">
              <Check className="w-8 h-8 sm:w-10 sm:h-10 text-green-400" />
            </div>
            <div className="text-center space-y-2">
              <h2 className="text-xl sm:text-2xl font-bold text-white">
                Cupom Resgatado!
              </h2>
              <p className="text-sm sm:text-base text-gray-400">
                Você ganhou R$ 5,00 de desconto na sua primeira compra.
                Use o código <span className="text-green-400 font-medium">BEMVINDO5</span>
              </p>
            </div>
            <button
              onClick={onClose}
              className="w-full py-2.5 sm:py-3 px-4 rounded-lg text-sm sm:text-base font-medium bg-gradient-to-r from-green-500 to-emerald-500 hover:from-green-600 hover:to-emerald-600 text-white transition-all duration-300"
            >
              Começar a Comprar
            </button>
          </div>
        ) : (
          <div className="space-y-4 sm:space-y-6">
            <div className="flex items-center gap-3 sm:gap-4">
              <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-gradient-to-br from-purple-500/20 to-pink-500/20 flex items-center justify-center">
                <Gift className="w-5 h-5 sm:w-6 sm:h-6 text-purple-400" />
              </div>
              <div>
                <h2 className="text-lg sm:text-xl font-bold text-white">
                  Ganhe R$ 5,00 de Desconto
                </h2>
                <p className="text-xs sm:text-sm text-gray-400">
                  Digite seu telefone para receber o cupom
                </p>
              </div>
            </div>

            <div className="space-y-3 sm:space-y-4">
              <div className="phone-input-container">
                <PhoneInput
                  country={'br'}
                  value={phone}
                  onChange={setPhone}
                  inputClass="!w-full !h-10 sm:!h-12 !px-4 !rounded-lg !bg-purple-500/10 !text-white !placeholder-gray-500 !border-0 focus:!outline-none focus:!ring-2 focus:!ring-purple-500"
                  containerClass="!bg-transparent"
                  buttonClass="!bg-purple-500/10 !border-0 !rounded-lg !h-10 sm:!h-12 hover:!bg-purple-500/20"
                  dropdownClass="!bg-[#1a0f2e] !text-gray-300"
                  searchClass="!bg-[#1a0f2e] !text-gray-300 !border-purple-500/20"
                  enableSearch
                  searchPlaceholder="Buscar país..."
                />
              </div>

              <button
                onClick={handleSubmit}
                disabled={phone.length < 10}
                className="w-full py-2.5 sm:py-3 px-4 rounded-lg text-sm sm:text-base font-medium bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Resgatar Cupom
              </button>

              <p className="text-[10px] sm:text-xs text-gray-500 text-center">
                Ao continuar, você concorda em receber comunicações sobre promoções e novidades.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 