import React from 'react';

interface PhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  required?: boolean;
}

export default function PhoneInput({
  value,
  onChange,
  className = '',
  placeholder = '(00) 00000-0000',
  required = false
}: PhoneInputProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove todos os caracteres não numéricos
    let phoneNumber = e.target.value.replace(/\D/g, '');

    // Limita a 11 dígitos
    phoneNumber = phoneNumber.slice(0, 11);

    // Formata o número
    let formattedNumber = phoneNumber;
    
    if (phoneNumber.length >= 2) {
      formattedNumber = `(${phoneNumber.slice(0, 2)})`;
      
      if (phoneNumber.length > 2) {
        formattedNumber += ` ${phoneNumber.slice(2)}`;
      }
      
      if (phoneNumber.length > 7) {
        formattedNumber = formattedNumber.slice(0, 10) + '-' + formattedNumber.slice(10);
      }
    }

    onChange(formattedNumber);
  };

  return (
    <input
      type="tel"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      required={required}
      className={`w-full bg-transparent text-white placeholder-gray-400 
                 border border-purple-500/20 rounded-lg px-3 py-2
                 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
                 focus:ring-offset-[#0a0118] ${className}`}
    />
  );
}
