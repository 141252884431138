import React, { useState } from 'react';
import { Search, ShoppingBag, Menu, MessageCircle, Home } from 'lucide-react';
import { useCart } from '../../hooks/useCart';
import { settings } from '../../config/settings';

interface BottomNavProps {
  onOpenSearch: () => void;
  onOpenMenu: () => void;
  onOpenCart: () => void;
}

export default function BottomNav({ onOpenSearch, onOpenMenu, onOpenCart }: BottomNavProps) {
  const cartItems = useCart((state) => state.items);
  const [isActive, setIsActive] = useState(false);
  
  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${settings.store.whatsapp}`, '_blank');
  };

  return (
    <nav 
      className="fixed bottom-0 left-0 right-0 bg-gray-900/95 backdrop-blur-lg border-t border-purple-500/20 z-50"
      onClick={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className={`scanline absolute top-0 left-0 right-0 h-[2px] overflow-hidden ${isActive ? 'active' : ''}`} />
      <div className="flex items-center justify-around px-2 py-3">
        <button
          onClick={handleWhatsAppClick}
          className="flex flex-col items-center gap-1 relative group"
        >
          <MessageCircle className="h-6 w-6 text-green-500 transition-transform duration-300 group-hover:scale-110" />
          <span className="text-[10px] text-gray-300 group-hover:text-green-400 transition-colors">WhatsApp</span>
        </button>

        <button
          onClick={onOpenSearch}
          className="flex flex-col items-center gap-1 relative group"
        >
          <Search className="h-6 w-6 text-gray-300 transition-transform duration-300 group-hover:scale-110" />
          <span className="text-[10px] text-gray-300 group-hover:text-purple-400 transition-colors">Buscar</span>
        </button>

        <a
          href="/"
          className="flex flex-col items-center gap-1 relative -mt-6 group"
        >
          <div className="w-14 h-14 rounded-full bg-gradient-to-br from-purple-500 to-pink-500 flex items-center justify-center shadow-lg
                        transition-all duration-300 group-hover:shadow-purple-500/50 group-hover:scale-110">
            <Home className="h-7 w-7 text-white" />
          </div>
          <span className="text-[10px] text-gray-300 group-hover:text-purple-400 transition-colors">Home</span>
        </a>

        <a
          href="/cart"
          className="flex flex-col items-center gap-1 relative group"
        >
          <div className="relative">
            <ShoppingBag className="h-6 w-6 text-gray-300 transition-transform duration-300 group-hover:scale-110" />
            {cartItems.length > 0 && (
              <span className="absolute -top-1 -right-1 bg-gradient-to-r from-primary-500 to-primary-600 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                {cartItems.length}
              </span>
            )}
          </div>
          <span className="text-[10px] text-gray-300 group-hover:text-purple-400 transition-colors">Carrinho</span>
        </a>

        <button
          onClick={onOpenMenu}
          className="flex flex-col items-center gap-1 relative group"
        >
          <Menu className="h-6 w-6 text-gray-300 transition-transform duration-300 group-hover:scale-110" />
          <span className="text-[10px] text-gray-300 group-hover:text-purple-400 transition-colors">Menu</span>
        </button>
      </div>
    </nav>
  );
}