import React, { Suspense, useEffect } from 'react';
import { Loader } from 'lucide-react';

// Eager loaded components
import Home from './pages/Home';

// Lazy loaded components com preload
const ProductDetails = React.lazy(() => import('./pages/ProductDetails'));
const CategoryPage = React.lazy(() => import('./pages/CategoryPage'));
const About = React.lazy(() => import('./pages/About'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const Exchange = React.lazy(() => import('./pages/Exchange'));
const Contact = React.lazy(() => import('./pages/Contact'));
const CartPage = React.lazy(() => import('./pages/CartPage'));
const OrderSuccessPage = React.lazy(() => import('./pages/OrderSuccessPage'));
const OrderHistoryPage = React.lazy(() => import('./pages/OrderHistoryPage'));
const TermosDeUso = React.lazy(() => import('./pages/TermosDeUso'));
const PoliticaPrivacidade = React.lazy(() => import('./pages/PoliticaPrivacidade'));
const FormasEntrega = React.lazy(() => import('./pages/FormasEntrega'));
const CentralAjuda = React.lazy(() => import('./pages/CentralAjuda'));
const EmbalagemDiscreta = React.lazy(() => import('./pages/EmbalagemDiscreta'));
const TrocasDevolucoes = React.lazy(() => import('./pages/TrocasDevolucoes'));

// Função de preload
const preloadRoutes = () => {
  // Preload das páginas principais
  const preloadProductDetails = () => import('./pages/ProductDetails');
  const preloadCategoryPage = () => import('./pages/CategoryPage');

  // Inicia o preload em background
  setTimeout(() => {
    preloadProductDetails();
    preloadCategoryPage();
  }, 1000);
};

interface AppRoutesProps {
  onOpenSearch: () => void;
}

const LoadingFallback = () => (
  <div className="min-h-screen bg-[#0a0118] flex items-center justify-center">
    <Loader className="w-8 h-8 text-purple-500 animate-spin" />
  </div>
);

export default function AppRoutes({ onOpenSearch }: AppRoutesProps) {
  useEffect(() => {
    preloadRoutes();
  }, []);

  // Pega o caminho atual da URL
  const path = window.location.pathname;
  
  // Extrai parâmetros da URL se necessário
  const getIdFromPath = (prefix: string) => {
    if (path.startsWith(prefix)) {
      return path.replace(prefix, '');
    }
    return null;
  };

  // Renderiza o componente apropriado baseado no caminho
  const renderRoute = () => {
    if (path === '/') {
      return <Home onOpenSearch={onOpenSearch} />;
    }

    if (path.startsWith('/product/')) {
      const id = getIdFromPath('/product/');
      return <ProductDetails />;
    }

    if (path.startsWith('/category/')) {
      const id = getIdFromPath('/category/');
      return <CategoryPage />;
    }

    if (path === '/about') {
      return <About />;
    }

    if (path === '/privacy') {
      return <Privacy />;
    }

    if (path === '/exchange') {
      return <Exchange />;
    }

    if (path === '/contact') {
      return <Contact />;
    }

    if (path === '/cart') {
      return <CartPage />;
    }

    if (path === '/order-success') {
      return <OrderSuccessPage />;
    }

    if (path === '/orders') {
      return <OrderHistoryPage />;
    }

    if (path === '/termos-de-uso') {
      return <TermosDeUso />;
    }

    if (path === '/politica-de-privacidade') {
      return <PoliticaPrivacidade />;
    }

    if (path === '/formas-de-entrega') {
      return <FormasEntrega />;
    }

    if (path === '/central-de-ajuda') {
      return <CentralAjuda />;
    }

    if (path === '/embalagem-discreta') {
      return <EmbalagemDiscreta />;
    }

    if (path === '/trocas-e-devolucoes') {
      return <TrocasDevolucoes />;
    }

    // Página não encontrada - redireciona para home
    window.location.href = '/';
    return null;
  };

  return (
    <Suspense fallback={<LoadingFallback />}>
      {renderRoute()}
    </Suspense>
  );
}