import React from 'react';
import { motion } from 'framer-motion';
import { Heart, MapPin, Clock, Phone, Instagram, Facebook, MessageCircle } from 'lucide-react';

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  whileInView: { opacity: 1, y: 0 },
  viewport: { once: true },
  transition: { duration: 0.5 }
};

const socialLinks = [
  {
    icon: <Instagram className="w-5 h-5" />,
    href: "https://instagram.com/idesire.goiania",
    color: "hover:bg-gradient-to-tr from-purple-500 via-pink-500 to-orange-500"
  },
  {
    icon: <Facebook className="w-5 h-5" />,
    href: "https://facebook.com/idesire.goiania",
    color: "hover:bg-blue-600"
  },
  {
    icon: <MessageCircle className="w-5 h-5" />,
    href: "https://wa.me/5562999999999",
    color: "hover:bg-green-500"
  }
];

export default function Footer() {
  return (
    <footer className="relative z-10">
      {/* Gradient Background */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-purple-900/10 to-purple-900/20 pointer-events-none" />

      {/* Main Content */}
      <div className="relative z-20 backdrop-blur-sm bg-[#120826]/80">
        <div className="max-w-7xl mx-auto px-4 pt-16 pb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-12">
            {/* Páginas */}
            <motion.div
              variants={fadeInUp}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
              className="space-y-4"
            >
              <h3 className="text-xl font-medium bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                Páginas
              </h3>
              <ul className="space-y-3">
                <motion.li whileHover={{ x: 5 }} transition={{ type: "spring", stiffness: 300 }}>
                  <a href="/termos-de-uso" className="text-gray-300 hover:text-purple-400 text-sm flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-purple-500/50" />
                    Termos de Uso
                  </a>
                </motion.li>
                <motion.li whileHover={{ x: 5 }} transition={{ type: "spring", stiffness: 300 }}>
                  <a href="/politica-de-privacidade" className="text-gray-300 hover:text-purple-400 text-sm flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-purple-500/50" />
                    Política de Privacidade
                  </a>
                </motion.li>
                <motion.li whileHover={{ x: 5 }} transition={{ type: "spring", stiffness: 300 }}>
                  <a href="/formas-de-entrega" className="text-gray-300 hover:text-purple-400 text-sm flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-purple-500/50" />
                    Formas de Entrega
                  </a>
                </motion.li>
                <motion.li whileHover={{ x: 5 }} transition={{ type: "spring", stiffness: 300 }}>
                  <a href="/central-de-ajuda" className="text-gray-300 hover:text-purple-400 text-sm flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-purple-500/50" />
                    Central de Ajuda
                  </a>
                </motion.li>
                <motion.li whileHover={{ x: 5 }} transition={{ type: "spring", stiffness: 300 }}>
                  <a href="/embalagem-discreta" className="text-gray-300 hover:text-purple-400 text-sm flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-purple-500/50" />
                    Embalagem Discreta
                  </a>
                </motion.li>
                <motion.li whileHover={{ x: 5 }} transition={{ type: "spring", stiffness: 300 }}>
                  <a href="/trocas-e-devolucoes" className="text-gray-300 hover:text-purple-400 text-sm flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-purple-500/50" />
                    Trocas e Devoluções
                  </a>
                </motion.li>
              </ul>
            </motion.div>

            {/* Contato e Localização */}
            <motion.div
              variants={fadeInUp}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
              className="space-y-4"
            >
              <h3 className="text-xl font-medium bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                Contato
              </h3>
              <ul className="space-y-3">
                <li className="flex items-center gap-2 text-gray-300 text-sm">
                  <MapPin className="w-4 h-4 text-purple-400" />
                  Goiânia, GO
                </li>
                <li className="flex items-center gap-2 text-gray-300 text-sm">
                  <Clock className="w-4 h-4 text-purple-400" />
                  Seg - Sáb, 13h às 21h
                </li>
                <li className="flex items-center gap-2 text-gray-300 text-sm">
                  <Phone className="w-4 h-4 text-purple-400" />
                  (62) 9 9999-9999
                </li>
              </ul>

              {/* Social Media */}
              <div className="flex gap-3 mt-6">
                {socialLinks.map((social, index) => (
                  <motion.a
                    key={index}
                    href={social.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`p-2 rounded-lg bg-purple-500/10 text-purple-400 transition-colors ${social.color}`}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {social.icon}
                  </motion.a>
                ))}
              </div>
            </motion.div>

            {/* Desenvolvido por */}
            <motion.div
              variants={fadeInUp}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
              className="flex flex-col items-start"
            >
              <h3 className="text-xl font-medium bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent mb-4">
                Desenvolvido por
              </h3>
              <motion.a 
                href="https://fluxiia.com" 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:opacity-80 transition-opacity"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <img 
                  src="https://personal.fluxiia.com/images/fluxi-logo.png" 
                  alt="Fluxiia" 
                  className="h-8 object-contain"
                />
              </motion.a>
            </motion.div>

            {/* Informações da Empresa */}
            <motion.div
              variants={fadeInUp}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
              className="md:col-span-2 lg:col-span-1"
            >
              <h3 className="text-xl font-medium bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent mb-4">
                Informações Legais
              </h3>
              <div className="text-gray-300 text-sm space-y-2">
                <p>
                  <strong className="text-purple-300">CNPJ:</strong> 50.476.085/0001-57
                </p>
                <p>
                  <strong className="text-purple-300">Razão Social:</strong> 50.476.085 DHIELI DA CONCEICAO CRUZ
                </p>
              </div>
            </motion.div>
          </div>

          {/* Copyright */}
          <motion.div
            variants={fadeInUp}
            initial="initial"
            whileInView="whileInView"
            viewport={{ once: true }}
            className="border-t border-purple-500/20 pt-8 text-center"
          >
            <p className="text-gray-300 text-sm flex items-center justify-center gap-2">
              Feito com <Heart className="w-4 h-4 text-pink-400 animate-pulse" /> pela iDesire Sex Shop
            </p>
            <p className="text-gray-400 text-xs mt-2">
              Todos os direitos reservados © {new Date().getFullYear()}
            </p>
          </motion.div>
        </div>
      </div>
    </footer>
  );
}