import React from 'react';
import { Helmet } from 'react-helmet-async';
import { settings } from '../../config/settings';

interface MetaTagsProps {
  title?: string;
  description?: string;
  image?: string;
  noindex?: boolean;
}

export default function MetaTags({ 
  title, 
  description = settings.store.description,
  image = settings.store.logo.url,
  noindex = false
}: MetaTagsProps) {
  const { store } = settings;
  
  const fullTitle = title 
    ? `${title} | ${store.name}`
    : store.name;

  const canonicalUrl = `${store.url}${window.location.pathname}`;

  return (
    <Helmet>
      {/* Títulos */}
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} />
      <meta name="twitter:title" content={fullTitle} />
      
      {/* Descrições */}
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      
      {/* Imagens */}
      <meta property="og:image" content={image} />
      <meta name="twitter:image" content={image} />
      
      {/* URLs */}
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:url" content={canonicalUrl} />
      
      {/* Tipo */}
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      
      {/* Indexação */}
      {noindex && <meta name="robots" content="noindex,nofollow" />}
      
      {/* Favicon */}
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0a0118" />
      <meta name="theme-color" content="#0a0118" />
      <meta name="msapplication-TileColor" content="#0a0118" />
    </Helmet>
  );
}
