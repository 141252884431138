import { settings } from '../config/settings';

export const getThemeColor = (path: string): string => {
  const parts = path.split('.');
  let value: any = settings.theme.colors;
  
  for (const part of parts) {
    value = value[part];
    if (!value) return '';
  }
  
  return value;
};

export const getGradient = (from: string, to: string): string => {
  return `bg-gradient-to-r from-[${from}] to-[${to}]`;
};

export const getTextGradient = (from: string, to: string): string => {
  return `bg-gradient-to-r from-[${from}] to-[${to}] bg-clip-text text-transparent`;
};

export const getGlassBackground = (): string => {
  const { glass } = settings.theme.effects;
  return `bg-white/[${glass.opacity}] backdrop-blur-${glass.blur} border border-white/[${glass.borderOpacity}]`;
};

export const getRadialGradient = (): string => {
  const { radial } = settings.theme.effects.gradient;
  const { primary, background } = settings.theme.colors;
  return `bg-gradient-radial from-[${primary.from}]/[${radial.opacity}] via-[${background.primary}] to-[${background.primary}]`;
};

export const getGridPattern = (): string => {
  const { grid } = settings.theme.effects;
  return `bg-[url('${grid.pattern}')] bg-[length:${grid.size}] opacity-[${grid.opacity}]`;
};

// Classes CSS pré-definidas baseadas no tema
export const themeClasses = {
  text: {
    primary: `text-[${settings.theme.colors.text.primary}]`,
    secondary: `text-[${settings.theme.colors.text.secondary}]`,
    accent: `text-[${settings.theme.colors.text.accent}]`
  },
  background: {
    primary: `bg-[${settings.theme.colors.background.primary}]`,
    card: getGlassBackground()
  },
  gradient: {
    primary: getGradient(settings.theme.colors.primary.from, settings.theme.colors.primary.to),
    secondary: getGradient(settings.theme.colors.secondary.from, settings.theme.colors.secondary.to),
    radial: getRadialGradient(),
    text: {
      primary: getTextGradient(settings.theme.colors.primary.from, settings.theme.colors.primary.to),
      secondary: getTextGradient(settings.theme.colors.secondary.from, settings.theme.colors.secondary.to)
    }
  },
  effects: {
    grid: getGridPattern(),
    glass: getGlassBackground()
  }
}; 