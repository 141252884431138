import React, { useEffect, useRef, useState } from 'react';
import { MapPin, Clock, Phone, Store, Instagram } from 'lucide-react';
import { settings } from '../../config/settings';

export default function AboutStore() {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  if (!isVisible) {
    return <div ref={containerRef} className="min-h-[200px]" />;
  }

  const formatPhone = (phone: string) => {
    const cleaned = phone.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
  };

  return (
    <div ref={containerRef} className="space-y-8 animate-fade-in pb-24">
      {/* Sobre Nós */}
      <div className="glass-card rounded-2xl p-6 relative overflow-hidden">
        <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-purple-500/20 to-pink-500/20 blur-3xl" />
        <div className="relative space-y-6">
          <h2 className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
            Sobre Nós
          </h2>

          <div className="space-y-6 text-gray-300 text-sm leading-relaxed">
            <div className="md:flex md:gap-8 items-start">
              <div className="md:flex-1 space-y-4">
                <p>
                  A iDesire Goiânia, fundada em 2018, tem como missão proporcionar aos nossos clientes uma experiência de prazer em todas as suas formas. Em nossa loja, garantimos a confiança na compra de produtos eróticos cuidadosamente escolhidos por especialistas.
                </p>
                <p>
                  Nossa história começou com um sonho de criar um espaço onde as pessoas pudessem explorar sua sexualidade de forma segura e sem julgamentos. Hoje, somos referência em Goiânia e região, oferecendo não apenas produtos, mas também conhecimento e suporte para nossos clientes.
                </p>
              </div>
              <div className="mt-6 md:mt-0 md:w-[510px] rounded-2xl overflow-hidden">
                <img
                  src="https://idesire.goiania.br/loja/wp-content/uploads/2024/08/IMG-20231112-WA0026-2.jpg"
                  alt="Fachada da loja iDesire Sex Shop"
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="space-y-4">
              <p>
                Cada item disponível foi selecionado com carinho e critério, permitindo que você encontre o produto perfeito com facilidade. Nossa equipe é treinada para oferecer orientação especializada, garantindo que você faça a escolha ideal para suas necessidades.
              </p>
              <p>
                Além da loja online, contamos com uma loja física acolhedora e discreta, onde você pode retirar seus pedidos ou receber atendimento personalizado. Nosso compromisso é proporcionar uma experiência de compra confortável e segura, sempre respeitando sua privacidade.
              </p>
              <p>
                Na iDesire, acreditamos que o autoconhecimento e o prazer são direitos fundamentais. Por isso, trabalhamos incansavelmente para oferecer os melhores produtos, com qualidade garantida e preços justos. Aqui, você encontra tudo o que precisa para explorar suas fantasias e desejos com segurança e confiança!
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Informações da Loja */}
      <div className="glass-card rounded-2xl p-6 space-y-6">
        <h2 className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
          Visite Nossa Loja
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Informações de Contato */}
          <div className="space-y-6">
            {/* Endereço */}
            <div className="flex items-start gap-3">
              <MapPin className="w-5 h-5 text-purple-400 mt-1 flex-shrink-0" />
              <div className="space-y-1">
                <h3 className="text-white font-medium">Endereço</h3>
                <p className="text-gray-300 text-sm">
                  {settings.store.address.street}, {settings.store.address.number}
                  <br />
                  {settings.store.address.complement}
                  <br />
                  {settings.store.address.neighborhood}
                  <br />
                  {settings.store.address.city} - {settings.store.address.state}
                  <br />
                  CEP: {settings.store.address.zipCode}
                </p>
              </div>
            </div>

            {/* Horário */}
            <div className="flex items-start gap-3">
              <Clock className="w-5 h-5 text-purple-400 mt-1 flex-shrink-0" />
              <div className="space-y-1">
                <h3 className="text-white font-medium">Horário de Funcionamento</h3>
                <p className="text-gray-300 text-sm">
                  Segunda a Sábado
                  <br />
                  {settings.store.hours.pickup.hours[0].open} às {settings.store.hours.pickup.hours[0].close}
                </p>
              </div>
            </div>

            {/* Contato */}
            <div className="flex items-start gap-3">
              <Phone className="w-5 h-5 text-purple-400 mt-1 flex-shrink-0" />
              <div className="space-y-1">
                <h3 className="text-white font-medium">Contato</h3>
                <div className="space-y-2">
                  <p className="text-gray-300 text-sm">
                    Telefone: {formatPhone(settings.store.contact.phone)}
                  </p>
                  <a
                    href={`https://wa.me/${settings.store.contact.whatsapp}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-sm text-purple-400 hover:text-purple-300 transition-colors"
                  >
                    WhatsApp: {formatPhone(settings.store.contact.whatsapp.slice(2))}
                  </a>
                  <a
                    href="https://instagram.com/idesire_sexshop"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-sm text-purple-400 hover:text-purple-300 transition-colors"
                  >
                    <Instagram className="w-4 h-4" />
                    @idesire_sexshop
                  </a>
                </div>
              </div>
            </div>

            {/* Link do Maps */}
            <a
              href={settings.store.address.googleMapsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 text-sm text-purple-400 hover:text-purple-300 transition-colors"
            >
              <Store className="w-4 h-4" />
              Ver no Google Maps
            </a>
          </div>

          {/* Mapa */}
          <div className="aspect-square rounded-lg overflow-hidden bg-purple-500/10">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d122281.86607856114!2d-49.3898904!3d-16.7114613!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935ef58223580479%3A0xe10dd42e707b8579!2sSex%20Shop%20Goi%C3%A2nia%20-%20iDesire%20Delivery%20de%20Produtos%20Er%C3%B3ticos!5e0!3m2!1spt-BR!2sbr!4v1723060268881!5m2!1spt-BR!2sbr"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Localização da iDesire Sex Shop"
            />
          </div>
        </div>
      </div>
    </div>
  );
} 