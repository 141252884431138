import React, { useState, useEffect } from 'react';
import { MapPin, Gift } from 'lucide-react';
import { settings } from '../../config/settings';
import DeliveryCheckModal from '../delivery/DeliveryCheckModal';
import CouponRewardModal from '../modals/CouponRewardModal';

interface HeaderMobileProps {
  onOpenSearch?: () => void;
}

export default function HeaderMobile({ onOpenSearch }: HeaderMobileProps) {
  const { store } = settings;
  const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState<{
    address: string;
    isInDeliveryArea: boolean;
  } | null>(null);

  useEffect(() => {
    // Carrega o endereço salvo ao iniciar
    const savedAddress = localStorage.getItem('deliveryAddress');
    if (savedAddress) {
      const address = JSON.parse(savedAddress);
      setDeliveryAddress({
        address: address.address,
        isInDeliveryArea: true // Se está no localStorage, assumimos que está na área
      });
    }
  }, []);

  const handleAddressSelect = (address: {
    lat: number;
    lng: number;
    address: string;
    isInDeliveryArea: boolean;
  }) => {
    setDeliveryAddress({
      address: address.address,
      isInDeliveryArea: address.isInDeliveryArea
    });
    setIsDeliveryModalOpen(false);
  };

  const handleCouponSubmit = (phone: string) => {
    // Aqui você pode implementar a lógica de envio do cupom
    console.log('Enviando cupom para:', phone);
  };

  return (
    <>
      <header className="sticky top-0 z-50 backdrop-blur-xl bg-[#0a0118]/80 border-b border-purple-500/20 md:hidden">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <a href="/" className="flex-shrink-0">
              <img
                src={store.logo.url}
                alt={store.logo.alt}
                className="h-8 w-auto"
                loading="eager"
              />
            </a>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setIsCouponModalOpen(true)}
                className="flex items-center gap-1.5 px-2 py-1 rounded-full bg-gradient-to-r from-purple-500/10 to-pink-500/10 hover:from-purple-500/20 hover:to-pink-500/20 transition-all duration-300"
              >
                <Gift className="w-3.5 h-3.5 text-purple-400" />
                <span className="text-[11px] text-gray-300">
                  Ganhe R$ 5,00
                </span>
              </button>
              <button
                onClick={() => setIsDeliveryModalOpen(true)}
                className="flex items-center gap-1.5 px-2 py-1 rounded-full bg-gradient-to-r from-purple-500/10 to-pink-500/10 hover:from-purple-500/20 hover:to-pink-500/20 transition-all duration-300"
              >
                <MapPin className={`w-3.5 h-3.5 ${deliveryAddress?.isInDeliveryArea ? 'text-green-400' : 'text-purple-400'}`} />
                {!deliveryAddress && (
                  <span className="text-[11px] text-gray-300">
                    Verificar entrega
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Modais */}
      <DeliveryCheckModal
        isOpen={isDeliveryModalOpen}
        onClose={() => setIsDeliveryModalOpen(false)}
        onAddressSelect={handleAddressSelect}
      />
      <CouponRewardModal
        isOpen={isCouponModalOpen}
        onClose={() => setIsCouponModalOpen(false)}
        onSubmit={handleCouponSubmit}
      />
    </>
  );
} 