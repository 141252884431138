import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import type { CartItemType } from '../types/cart';

interface CartStore {
  items: CartItemType[];
  total: number;
  addItem: (item: CartItemType) => void;
  removeItem: (id: number) => void;
  updateQuantity: (id: number, quantity: number) => void;
  clearCart: () => void;
}

const ensureValidNumber = (value: number): number => {
  return typeof value === 'number' && !isNaN(value) ? value : 0;
};

const calculateTotal = (items: CartItemType[]): number => {
  return items.reduce((sum, item) => {
    const price = ensureValidNumber(item.price);
    const quantity = ensureValidNumber(item.quantity);
    return sum + (price * quantity);
  }, 0);
};

export const useCart = create<CartStore>()(
  persist(
    (set) => ({
      items: [],
      total: 0,
      addItem: (item) =>
        set((state) => {
          const validItem = {
            ...item,
            price: ensureValidNumber(item.price),
            quantity: ensureValidNumber(item.quantity)
          };
          
          const existingItem = state.items.find((i) => i.id === validItem.id);
          if (existingItem) {
            const updatedItems = state.items.map((i) =>
              i.id === validItem.id ? { ...i, quantity: i.quantity + 1 } : i
            );
            return {
              items: updatedItems,
              total: calculateTotal(updatedItems),
            };
          }
          const updatedItems = [...state.items, { ...validItem, quantity: 1 }];
          return {
            items: updatedItems,
            total: calculateTotal(updatedItems),
          };
        }),
      removeItem: (id) =>
        set((state) => {
          const updatedItems = state.items.filter((i) => i.id !== id);
          return {
            items: updatedItems,
            total: calculateTotal(updatedItems),
          };
        }),
      updateQuantity: (id, quantity) =>
        set((state) => {
          const validQuantity = ensureValidNumber(quantity);
          const updatedItems = state.items.map((i) =>
            i.id === id ? { ...i, quantity: validQuantity } : i
          );
          return {
            items: updatedItems,
            total: calculateTotal(updatedItems),
          };
        }),
      clearCart: () => set({ items: [], total: 0 }),
    }),
    {
      name: 'cart-storage',
      storage: createJSONStorage(() => localStorage),
      onRehydrateStorage: (state) => {
        // Recalcula o total ao recarregar o estado do localStorage
        if (state && state.items) {
          const validItems = state.items.map(item => ({
            ...item,
            price: ensureValidNumber(item.price),
            quantity: ensureValidNumber(item.quantity)
          }));
          return {
            ...state,
            items: validItems,
            total: calculateTotal(validItems)
          };
        }
        return state;
      }
    }
  )
);
