import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from 'react-query';
import { woocommerceApi } from '../../lib/woocommerce';

interface PrefetchProps {
  children: React.ReactNode;
  productId?: number;
  categoryId?: number;
  prefetchOnHover?: boolean;
}

export default function Prefetch({ children, productId, categoryId, prefetchOnHover = false }: PrefetchProps) {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });
  const queryClient = useQueryClient();

  const prefetchData = async () => {
    try {
      // Não pré-carrega dados no carrinho ou checkout
      if (window.location.pathname.includes('/cart') || window.location.pathname.includes('/checkout')) {
        return;
      }

      if (productId) {
        await queryClient.prefetchQuery(
          ['product', productId],
          () => woocommerceApi.getProduct(productId),
          { staleTime: 5 * 60 * 1000 }
        );

        await queryClient.prefetchQuery(
          ['product-reviews', productId],
          () => woocommerceApi.getProductReviews(productId),
          { staleTime: 5 * 60 * 1000 }
        );
      }

      if (categoryId) {
        await queryClient.prefetchInfiniteQuery(
          ['products', { category: categoryId }],
          ({ pageParam = 1 }) => woocommerceApi.getProducts({ 
            category: categoryId,
            page: pageParam,
            per_page: 10
          }),
          { staleTime: 5 * 60 * 1000 }
        );
      }
    } catch (error) {
      console.warn('Prefetch error:', error);
    }
  };

  useEffect(() => {
    if (!inView) return;
    prefetchData();
  }, [inView, productId, categoryId]);

  const handleMouseEnter = () => {
    if (prefetchOnHover) {
      prefetchData();
    }
  };

  return (
    <div 
      ref={ref}
      onMouseEnter={handleMouseEnter}
    >
      {children}
    </div>
  );
}
