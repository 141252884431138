import React, { useState } from 'react';
import { Search, ShoppingBag, Menu, ChevronDown, MapPin, Gift } from 'lucide-react';
import { settings } from '../../config/settings';
import { useCategories } from '../../hooks/useCategories';
import DeliveryCheckModal from '../delivery/DeliveryCheckModal';
import CouponRewardModal from '../modals/CouponRewardModal';

interface DesktopHeaderProps {
  onOpenSearch: () => void;
  onOpenMenu: () => void;
  onOpenCart: () => void;
}

// Categorias que devem ser excluídas do menu
const EXCLUDED_CATEGORIES = [
  'Para Elas',
  'Para eles',
  'Kits',
  'Variados',
  'Embalagens Para guardar o toys',
  'Maquiagem'
];

export default function DesktopHeader({ onOpenSearch, onOpenMenu, onOpenCart }: DesktopHeaderProps) {
  const { store } = settings;
  const { data: allCategories } = useCategories();
  const [hoveredCategory, setHoveredCategory] = useState<number | null>(null);
  const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState<{
    address: string;
    isInDeliveryArea: boolean;
  } | null>(null);

  // Filtra e ordena categorias pai alfabeticamente
  const parentCategories = React.useMemo(() => {
    const filtered = allCategories?.filter(cat => 
      !cat.parent && !EXCLUDED_CATEGORIES.includes(cat.name)
    ) || [];
    
    return filtered.sort((a, b) => a.name.localeCompare(b.name));
  }, [allCategories]);

  // Obtém subcategorias ordenadas alfabeticamente
  const getSubcategories = (parentId: number) => {
    const subcategories = allCategories?.filter(cat => cat.parent === parentId) || [];
    return subcategories.sort((a, b) => a.name.localeCompare(b.name));
  };

  // Carrega o endereço salvo ao iniciar
  React.useEffect(() => {
    const savedAddress = localStorage.getItem('deliveryAddress');
    if (savedAddress) {
      const address = JSON.parse(savedAddress);
      setDeliveryAddress({
        address: address.address,
        isInDeliveryArea: true
      });
    }
  }, []);

  const handleAddressSelect = (address: {
    lat: number;
    lng: number;
    address: string;
    isInDeliveryArea: boolean;
  }) => {
    setDeliveryAddress({
      address: address.address,
      isInDeliveryArea: address.isInDeliveryArea
    });
    setIsDeliveryModalOpen(false);
  };

  return (
    <header className="hidden md:block fixed top-0 left-0 right-0 z-50">
      {/* Header Mid - Logo, Busca, Cupom e Entrega */}
      <div className="bg-[#0a0118]/90 backdrop-blur-xl border-b border-purple-500/20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="h-16 flex items-center justify-between gap-8">
            {/* Logo */}
            <a href="/" className="flex-shrink-0">
              <img
                src={store.logo.url}
                alt={store.logo.alt}
                className="h-8 w-auto object-contain mix-blend-luminosity"
              />
            </a>

            {/* Search Bar */}
            <div className="flex-1 max-w-xl">
              <div className="relative">
                <input
                  type="text"
                  placeholder="O que você procura?"
                  className="w-full pl-10 pr-4 py-2 bg-white/5 border border-purple-500/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                  onClick={onOpenSearch}
                  readOnly
                />
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              </div>
            </div>

            {/* Actions */}
            <div className="flex items-center gap-4">
              {/* Cupom */}
              <button
                onClick={() => setIsCouponModalOpen(true)}
                className="flex items-center gap-2 px-3 py-1.5 rounded-lg bg-gradient-to-r from-purple-500/10 to-pink-500/10 hover:from-purple-500/20 hover:to-pink-500/20 transition-all duration-300"
              >
                <Gift className="w-4 h-4 text-purple-400" />
                <span className="text-sm text-gray-300">
                  Ganhe R$ 5,00
                </span>
              </button>

              {/* Delivery Check */}
              <button
                onClick={() => setIsDeliveryModalOpen(true)}
                className="flex items-center gap-2 px-3 py-1.5 rounded-lg bg-gradient-to-r from-purple-500/10 to-pink-500/10 hover:from-purple-500/20 hover:to-pink-500/20 transition-all duration-300"
              >
                <MapPin className={`w-4 h-4 ${deliveryAddress?.isInDeliveryArea ? 'text-green-400' : 'text-purple-400'}`} />
                <span className="text-sm text-gray-300">
                  {deliveryAddress ? 'Entrega disponível' : 'Verificar entrega'}
                </span>
              </button>

              {/* Cart */}
              <button
                onClick={onOpenCart}
                className="p-2 text-gray-300 hover:text-white transition-colors"
                aria-label="Carrinho"
              >
                <ShoppingBag className="w-5 h-5" />
              </button>

              {/* Menu */}
              <button
                onClick={onOpenMenu}
                className="p-2 text-gray-300 hover:text-white transition-colors"
                aria-label="Menu"
              >
                <Menu className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Header Bottom - Categories Menu */}
      <div className="bg-[#0a0118] border-b border-purple-500/10">
        <div className="max-w-7xl mx-auto px-4">
          <nav className="flex justify-center">
            {parentCategories.map((category) => (
              <div
                key={category.id}
                className="relative group"
                onMouseEnter={() => setHoveredCategory(category.id)}
                onMouseLeave={() => setHoveredCategory(null)}
              >
                <a
                  href={`/category/${category.id}`}
                  className="flex items-center gap-2 px-6 py-3.5 text-sm font-medium text-gray-300 hover:text-white transition-all duration-300 group relative"
                >
                  {category.image && (
                    <div className="w-8 h-8 rounded-lg overflow-hidden">
                      <img
                        src={category.image.src}
                        alt={category.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  )}
                  {category.name}
                  <ChevronDown className="w-4 h-4 transition-transform duration-300 group-hover:rotate-180" />
                  
                  {/* Hover Effect Line */}
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-purple-500 to-pink-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300" />
                </a>

                {/* Dropdown Menu */}
                {hoveredCategory === category.id && (
                  <div className="absolute top-full left-1/2 -translate-x-1/2 w-[600px] pt-4 opacity-0 translate-y-2 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300">
                    <div className="bg-[#0a0118] rounded-xl overflow-hidden shadow-2xl border border-purple-500/20">
                      <div className="p-6">
                        {/* Category Header */}
                        <div className="flex items-center gap-4 pb-4 mb-4 border-b border-purple-500/20">
                          {category.image ? (
                            <img
                              src={category.image.src}
                              alt={category.name}
                              className="w-16 h-16 rounded-xl object-cover"
                            />
                          ) : (
                            <div className="w-16 h-16 rounded-xl bg-gradient-to-br from-purple-500/20 to-pink-500/20" />
                          )}
                          <div>
                            <h3 className="text-lg font-medium text-white">{category.name}</h3>
                            <p className="text-sm text-gray-400">{category.count} produtos</p>
                          </div>
                        </div>

                        {/* Subcategories Grid */}
                        <div className="grid grid-cols-2 gap-2">
                          {getSubcategories(category.id).map((subcat) => (
                            <a
                              key={subcat.id}
                              href={`/category/${subcat.id}`}
                              className="flex items-center gap-3 p-3 rounded-xl hover:bg-purple-500/10 transition-all duration-300 group border border-transparent hover:border-purple-500/20"
                            >
                              {subcat.image ? (
                                <div className="w-12 h-12 rounded-lg overflow-hidden">
                                  <img
                                    src={subcat.image.src}
                                    alt={subcat.name}
                                    className="w-full h-full object-cover"
                                  />
                                </div>
                              ) : (
                                <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-purple-500/10 to-pink-500/10" />
                              )}
                              <div>
                                <h4 className="text-sm font-medium text-white group-hover:text-purple-400 transition-colors">
                                  {subcat.name}
                                </h4>
                                <p className="text-xs text-gray-400">{subcat.count} produtos</p>
                              </div>
                            </a>
                          ))}
                        </div>

                        {/* Ver Todos Link */}
                        <div className="mt-4 pt-4 border-t border-purple-500/20 text-center">
                          <a
                            href={`/category/${category.id}`}
                            className="inline-flex items-center gap-2 text-sm text-purple-400 hover:text-purple-300 transition-colors"
                          >
                            <span>Ver todos os produtos</span>
                            <ChevronDown className="w-4 h-4 rotate-[-90deg]" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>

      {/* Modais */}
      <DeliveryCheckModal
        isOpen={isDeliveryModalOpen}
        onClose={() => setIsDeliveryModalOpen(false)}
        onAddressSelect={handleAddressSelect}
      />
      <CouponRewardModal
        isOpen={isCouponModalOpen}
        onClose={() => setIsCouponModalOpen(false)}
        onSubmit={(phone) => {
          console.log('Enviando cupom para:', phone);
          setIsCouponModalOpen(false);
        }}
      />
    </header>
  );
} 