import { useQuery } from 'react-query';
import { woocommerceApi } from '../lib/woocommerce';

export function useCategories() {
  return useQuery(
    'categories',
    () => woocommerceApi.getCategories(),
    {
      staleTime: 5 * 60 * 1000, // 5 minutos
      cacheTime: 30 * 60 * 1000, // 30 minutos
      refetchOnWindowFocus: false,
      retry: 3,
      persister: false // Desabilita persistência em disco
    }
  );
}