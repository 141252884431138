import axios from 'axios';
import { Product, Category, Review } from '../types/woocommerce';

const API_URL = 'https://backcamp.ifody.com.br/api'; // URL do nosso backend

// Configuração do axios
const api = axios.create({
  baseURL: API_URL
});

interface FetchProductsParams {
  page?: number;
  per_page?: number;
  search?: string;
  category?: number;
  orderby?: 'date' | 'price' | 'popularity' | 'rating';
  order?: 'asc' | 'desc';
  featured?: boolean;
  on_sale?: boolean;
}

export const woocommerceApi = {
  getProducts: async (params: FetchProductsParams = {}): Promise<{
    products: Product[];
    total: number;
    totalPages: number;
  }> => {
    try {
      const response = await api.get('/products', { params });

      return {
        products: response.data,
        total: parseInt(response.headers['X-WP-Total'] || '0', 10),
        totalPages: parseInt(response.headers['X-WP-TotalPages'] || '0', 10)
      };
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
      throw new Error('Falha ao buscar produtos. Por favor, verifique sua conexão e tente novamente.');
    }
  },

  getProduct: async (id: number): Promise<Product> => {
    try {
      const response = await api.get(`/products/${id}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar produto:', error);
      throw new Error('Falha ao buscar detalhes do produto');
    }
  },

  getProductReviews: async (productId: number, page: number = 1, per_page: number = 100): Promise<Review[]> => {
    try {
      const response = await api.get(`/products/${productId}/reviews`, {
        params: { page, per_page }
      });
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar avaliações:', error);
      throw new Error('Falha ao buscar avaliações do produto');
    }
  },

  getCategories: async (params: {
    per_page?: number;
    hide_empty?: boolean;
    parent?: number;
  } = {}): Promise<Category[]> => {
    try {
      const response = await api.get('/categories', { params });
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
      throw new Error('Falha ao buscar categorias');
    }
  },

  searchProducts: async (query: string): Promise<Product[]> => {
    try {
      const response = await api.get('/products', {
        params: { search: query }
      });
      return response.data;
    } catch (error) {
      console.error('Erro ao pesquisar produtos:', error);
      throw new Error('Falha ao pesquisar produtos. Por favor, tente novamente.');
    }
  },

  getCategoryProducts: async (categoryId: number, params: any = {}): Promise<{
    products: Product[];
    total: number;
    totalPages: number;
  }> => {
    try {
      const response = await api.get(`/categories/${categoryId}/products`, { params });
      
      return {
        products: response.data,
        total: parseInt(response.headers['x-wp-total'] || '0', 10),
        totalPages: parseInt(response.headers['x-wp-totalpages'] || '0', 10)
      };
    } catch (error) {
      console.error('Erro ao buscar produtos da categoria:', error);
      throw new Error('Falha ao buscar produtos da categoria. Por favor, verifique sua conexão e tente novamente.');
    }
  },
};

export async function fetchProductsBatch(ids: number[]): Promise<Product[]> {
  try {
    const products = await Promise.all(
      ids.map(id => woocommerceApi.getProduct(id))
    );
    return products;
  } catch (error) {
    console.error('Erro ao buscar lote de produtos:', error);
    throw new Error('Falha ao buscar lote de produtos');
  }
}

export async function fetchReviewsBatch(productIds: number[]): Promise<{ [key: number]: Review[] }> {
  try {
    const reviewsPromises = productIds.map(id => woocommerceApi.getProductReviews(id));
    const reviewsArray = await Promise.all(reviewsPromises);

    const reviewsByProduct: { [key: number]: Review[] } = {};
    productIds.forEach((id, index) => {
      reviewsByProduct[id] = reviewsArray[index];
    });

    return reviewsByProduct;
  } catch (error) {
    console.error('Erro ao buscar lote de avaliações:', error);
    throw new Error('Falha ao buscar lote de avaliações');
  }
}

export async function loadProductsWithDetails(productIds: number[]): Promise<Array<Product & { reviews: Review[] }>> {
  const [products, reviews] = await Promise.all([
    fetchProductsBatch(productIds),
    fetchReviewsBatch(productIds)
  ]);
  
  return products.map(product => ({
    ...product,
    reviews: reviews[product.id] || []
  }));
}