import create from 'zustand';

interface FiltersState {
  sortBy: string;
  filters: {
    availability: string[];
    price: string[];
    rating: string[];
  };
  setSortBy: (value: string) => void;
  toggleFilter: (type: keyof FiltersState['filters'], value: string) => void;
  clearFilters: () => void;
}

export const useFilters = create<FiltersState>((set) => ({
  sortBy: 'popularity',
  filters: {
    availability: [],
    price: [],
    rating: [],
  },
  setSortBy: (value) => set({ sortBy: value }),
  toggleFilter: (type, value) =>
    set((state) => ({
      filters: {
        ...state.filters,
        [type]: state.filters[type].includes(value)
          ? state.filters[type].filter((v) => v !== value)
          : [...state.filters[type], value],
      },
    })),
  clearFilters: () =>
    set({
      filters: {
        availability: [],
        price: [],
        rating: [],
      },
    }),
}));