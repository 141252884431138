import React, { useState, useEffect } from 'react';
import { X, ChevronRight, Info, Phone, FileText, Package, Instagram, Facebook, MessageCircle } from 'lucide-react';
import { useCategories } from '../../hooks/useCategories';
import { settings } from '../../config/settings';

interface Category {
  id: number;
  name: string;
  count: number;
  parent: number;
  description?: string;
  image?: {
    src: string;
  };
}

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SideMenu({ isOpen, onClose }: SideMenuProps) {
  const { data: allCategories } = useCategories();
  const [selectedParent, setSelectedParent] = useState<Category | null>(null);
  const { store } = settings;

  if (!isOpen) return null;

  // Filtra apenas categorias pai (parent = 0)
  const parentCategories = allCategories?.filter(cat => cat.parent === 0) || [];
  
  // Filtra subcategorias apenas quando uma categoria pai é selecionada
  const childCategories = selectedParent 
    ? allCategories?.filter(cat => cat.parent === selectedParent.id) || []
    : [];

  console.log('Categorias Pai:', parentCategories.length);
  console.log('Categorias Filho:', childCategories.length);

  const handleCategoryClick = (category: Category) => {
    setSelectedParent(category);
  };

  const handleBackClick = () => {
    setSelectedParent(null);
  };

  const handleWhatsAppClick = () => {
    if (store.whatsapp) {
      window.open(`https://wa.me/${store.whatsapp}`, '_blank');
    }
  };

  const handleViewAllClick = () => {
    if (selectedParent) {
      onClose();
      window.location.href = `/category/${selectedParent.id}`;
    }
  };

  return (
    <div className="fixed inset-0 z-50">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />

      <div className="absolute inset-y-0 left-0 w-full max-w-[320px] bg-[#0a0118] overflow-hidden">
        {/* Header com logo em destaque */}
        <div className="relative pt-3 pb-6 border-b border-purple-500/20">
          {/* Botão fechar */}
          <button
            onClick={onClose}
            className="absolute top-3 right-3 p-1.5 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-4 h-4 text-white" />
          </button>

          {/* Logo em destaque */}
          <div className="flex justify-center">
            <div className="w-32 h-12 bg-white rounded-xl p-2 shadow-lg shadow-purple-500/20">
              <img
                src={store.logo.url}
                alt={store.logo.alt}
                className="w-full h-full object-contain"
              />
            </div>
          </div>

          {/* Redes Sociais */}
          <div className="flex items-center justify-center gap-3 mt-4">
            {store.instagram && (
              <a
                href={`https://instagram.com/${store.instagram}`}
                target="_blank"
                rel="noopener noreferrer"
                className="p-1.5 rounded-full bg-gradient-to-br from-purple-500/20 to-pink-500/20 hover:from-purple-500 hover:to-pink-500 transition-all duration-300"
              >
                <Instagram className="w-4 h-4 text-white" />
              </a>
            )}
            {store.facebook && (
              <a
                href={`https://facebook.com/${store.facebook}`}
                target="_blank"
                rel="noopener noreferrer"
                className="p-1.5 rounded-full bg-gradient-to-br from-blue-500/20 to-blue-600/20 hover:from-blue-500 hover:to-blue-600 transition-all duration-300"
              >
                <Facebook className="w-4 h-4 text-white" />
              </a>
            )}
            {store.whatsapp && (
              <button
                onClick={handleWhatsAppClick}
                className="p-1.5 rounded-full bg-gradient-to-br from-green-500/20 to-green-600/20 hover:from-green-500 hover:to-green-600 transition-all duration-300"
              >
                <MessageCircle className="w-4 h-4 text-white" />
              </button>
            )}
          </div>
        </div>

        {/* Content - ajustando altura para considerar o novo header */}
        <div className="overflow-y-auto pb-20 h-[calc(100vh-132px-72px)]">
          {selectedParent ? (
            <div className="space-y-3 p-3">
              <button
                onClick={handleBackClick}
                className="flex items-center gap-1.5 text-purple-400 text-sm"
              >
                <ChevronRight className="w-4 h-4 rotate-180" />
                <span>Voltar</span>
              </button>

              {/* Categoria Pai com Opção Ver Todos */}
              <div className="glass-card rounded-lg overflow-hidden">
                <div className="relative h-24">
                  <img
                    src={selectedParent.image?.src || `https://source.unsplash.com/800x400/?${selectedParent.name}`}
                    alt={selectedParent.name}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-[#0a0118] to-transparent" />
                </div>
                <div className="p-3">
                  <h2 className="text-base font-bold text-white mb-1">
                    {selectedParent.name}
                  </h2>
                  <p className="text-xs text-gray-400 mb-2 line-clamp-2">
                    {selectedParent.description || `Explore todos os produtos da categoria ${selectedParent.name}`}
                  </p>
                  <button 
                    onClick={handleViewAllClick}
                    className="w-full py-1.5 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-md text-xs font-medium"
                  >
                    Ver Todos os Produtos
                  </button>
                </div>
              </div>

              {/* Subcategorias */}
              {childCategories.length > 0 && (
                <>
                  <h3 className="text-xs font-medium text-gray-400 mt-4 mb-2 px-1">Subcategorias</h3>
                  <div className="grid grid-cols-2 gap-2">
                    {childCategories.map((category) => (
                      <a
                        key={category.id}
                        href={`/category/${category.id}`}
                        onClick={onClose}
                        className="glass-card rounded-lg overflow-hidden group hover:scale-[1.02] transition-all duration-300"
                      >
                        <div className="relative h-20">
                          <img
                            src={category.image?.src || `https://source.unsplash.com/400x300/?${category.name}`}
                            alt={category.name}
                            className="absolute inset-0 w-full h-full object-cover"
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-[#0a0118] to-transparent" />
                        </div>
                        <div className="p-2">
                          <h3 className="text-xs font-medium text-white line-clamp-1">{category.name}</h3>
                          <p className="text-[10px] text-gray-400">{category.count} produtos</p>
                        </div>
                      </a>
                    ))}
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              {/* Apenas Categorias Pai */}
              <div className="grid grid-cols-2 gap-2 p-3">
                {parentCategories.map((category) => (
                  <button
                    key={category.id}
                    onClick={() => handleCategoryClick(category)}
                    className="glass-card rounded-lg overflow-hidden group hover:scale-[1.02] transition-all duration-300"
                  >
                    <div className="relative h-20">
                      <img
                        src={category.image?.src || `https://source.unsplash.com/400x300/?${category.name}`}
                        alt={category.name}
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-[#0a0118] to-transparent" />
                    </div>
                    <div className="p-2">
                      <h3 className="text-xs font-medium text-white line-clamp-1">{category.name}</h3>
                      <p className="text-[10px] text-gray-400">{category.count} produtos</p>
                    </div>
                  </button>
                ))}
              </div>

              {/* Links Úteis - adicionando padding bottom para evitar que fique atrás do BottomNav */}
              <div className="mt-6 px-3 pb-4">
                <h3 className="text-xs font-medium text-gray-400 px-2 mb-1">Links Úteis</h3>
                <div className="space-y-0.5">
                  <a
                    href="/orders"
                    className="flex items-center gap-2 px-2 py-1.5 text-gray-300 hover:text-purple-400 transition-colors"
                    onClick={onClose}
                  >
                    <Package className="w-4 h-4" />
                    <span className="text-sm">Meus Pedidos</span>
                  </a>
                  <a
                    href="/about"
                    className="flex items-center gap-2 px-2 py-1.5 text-gray-300 hover:text-purple-400 transition-colors"
                    onClick={onClose}
                  >
                    <Info className="w-4 h-4" />
                    <span className="text-sm">Sobre Nós</span>
                  </a>
                  <a
                    href="/contact"
                    className="flex items-center gap-2 px-2 py-1.5 text-gray-300 hover:text-purple-400 transition-colors"
                    onClick={onClose}
                  >
                    <Phone className="w-4 h-4" />
                    <span className="text-sm">Contato</span>
                  </a>
                  <a
                    href="/privacy"
                    className="flex items-center gap-2 px-2 py-1.5 text-gray-300 hover:text-purple-400 transition-colors"
                    onClick={onClose}
                  >
                    <FileText className="w-4 h-4" />
                    <span className="text-sm">Política de Privacidade</span>
                  </a>
                  <a
                    href="/exchange"
                    className="flex items-center gap-2 px-2 py-1.5 text-gray-300 hover:text-purple-400 transition-colors"
                    onClick={onClose}
                  >
                    <Package className="w-4 h-4" />
                    <span className="text-sm">Trocas e Devoluções</span>
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
